#confirm-btn {
  background-color: #1b5e20;
}
#confirm-btn:hover {
  background-color: #388e3c;
}
.done {
  color: #8bc34a;
}
.not_done {
  color: #f44336;
}
.file_dropzone {
  border: 5px dotted #757575;
  border-radius: 5px;
  padding: 25px;
  color: #bdbdbd;
}
