body {
  background: #f5f5f5;
}
.btn {
  background-color: #b71c1c;
}
.btn:hover {
  background-color: #d32f2f;
}
#register-btn {
  background-color: #4527a0;
}
#register-btn:hover {
  background-color: #5e35b1;
}
.back-btn {
  background-color: #263238;
}
.back-btn:hover {
  background-color: #455a64;
}
.forgotten_link {
  color: #e0e0e0;
}
.forgotten_link:hover {
  color: #757575;
}
.error-message {
  color: #ef5350;
}
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]) {
  border-bottom-color: #d32f2f;
  -webkit-box-shadow: 0 1px 0 0 #d32f2f;
  box-shadow: 0 1px 0 0 #d32f2f;
  color: #d32f2f !important;
}
input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label {
  color: #d32f2f;
}
input.valid[type="text"]:not(.browser-default),
input.valid[type="password"]:not(.browser-default),
input.valid[type="email"]:not(.browser-default) {
  color: #9ccc65 !important;
}
input.valid[type="text"]:not(.browser-default) + label,
input.valid[type="password"]:not(.browser-default) + label,
input.valid[type="email"]:not(.browser-default) + label {
  color: #9ccc65;
}
input.invalid[type="text"]:not(.browser-default),
input.invalid[type="password"]:not(.browser-default),
input.invalid[type="email"]:not(.browser-default) {
  border-bottom-color: #9575cd;
  -webkit-box-shadow: 0 1px 0 0 #9575cd;
  box-shadow: 0 1px 0 0 #9575cd;
  color: #9575cd !important;
}
input.invalid[type="text"]:not(.browser-default) + label,
input.invalid[type="password"]:not(.browser-default) + label,
input.invalid[type="email"]:not(.browser-default) + label {
  color: #9575cd;
}
@media (max-width: 991px) {
  div#wrapper {
    margin-bottom: 0;
    background: none;
  }
  div#wrapper div#background-wrapper {
    display: none;
  }
  div#wrapper div#index-wrapper {
    background: #f5f5f5;
    height: 100vh;
  }
  div#wrapper div#login-wrapper {
    background: #f5f5f5;
    height: 100vh;
  }
}
@media (min-width: 992px) {
  div#wrapper {
    margin-bottom: 0;
    background-image: url("../img/schuleLoginBackground.png");
    background-size: 100%;
    background-position: center;
  }
  div#wrapper div#background-wrapper {
    background: transparent;
    height: 100vh;
  }
  div#wrapper div#index-wrapper {
    color: #f5f5f5;
    padding-left: 9%;
    padding-right: 9%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #212121;
    background: linear-gradient(to right, rgba(30,30,30,0), rgba(30,30,30,0.25) 3%, rgba(30,30,30,0.5) 7%, rgba(30,30,30,0.7) 10%, rgba(30,30,30,0.85) 12%, rgba(30,30,30,0.95) 15%, rgba(30,30,30,0.95) 50%, rgba(30,30,30,0.95) 85%, rgba(30,30,30,0.85) 87%, rgba(30,30,30,0.7) 90%, rgba(30,30,30,0.5) 93%, rgba(30,30,30,0.25) 97%, rgba(30,30,30,0));
  }
  div#wrapper div#login-wrapper {
    color: #f5f5f5;
    padding-left: 9%;
    padding-right: 9%;
    height: 100vh;
    background: #212121;
    background: linear-gradient(to right, rgba(30,30,30,0), rgba(30,30,30,0.25) 3%, rgba(30,30,30,0.5) 7%, rgba(30,30,30,0.7) 10%, rgba(30,30,30,0.85) 12%, rgba(30,30,30,0.95) 15%, rgba(30,30,30,0.95) 50%, rgba(30,30,30,0.95) 85%, rgba(30,30,30,0.85) 87%, rgba(30,30,30,0.7) 90%, rgba(30,30,30,0.5) 93%, rgba(30,30,30,0.25) 97%, rgba(30,30,30,0));
  }
  div#wrapper div#login-wrapper input {
    color: #eee;
  }
  div#wrapper div#login-wrapper form > .row {
    margin-right: 0;
  }
}
